import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';

import Layout from './src/components/layout/Layout';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const excludedPaths = ["/privacy-policy", "/privacy-policy/*", "/partners", "/partners/*", "/terms-of-service", "/terms-of-service/*"];

  if (excludedPaths.includes(props.path)) {
    return element;
  }
  
  return <Layout>{element}</Layout>;
};

export const onInitialClientRender = () => {
  document.body.classList.remove('loading');
};
