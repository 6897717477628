export enum Breakpoint {
  base = 0,
  sm = 576,
  md = 768,
  lg = 1024,
  max = 1920,
}

type BreakpointKeys = keyof typeof Breakpoint;
type BreakpointKey = { [key in BreakpointKeys]: string };

const breakpointsAsValueOnly = Object.keys(Breakpoint)
  .filter((key) => !isNaN(+key))
  .map((key) => +key);

export const above = (breakpoint: Breakpoint): string =>
  `@media only screen and (min-width: ${breakpoint}px)`;

export const below = (breakpoint: Breakpoint): string =>
  `@media only screen and (max-width: ${breakpoint - 0.2}px)`;

export const between = (min: Breakpoint, max: Breakpoint): string => {
  const index = breakpointsAsValueOnly.indexOf(max) - 1;
  const validMax =
    index < breakpointsAsValueOnly.length - 1 &&
    breakpointsAsValueOnly[index + 1];
  return validMax
    ? `@media only screen and (min-width: ${min}px) and (max-width: ${
        validMax - 0.2
      }px)`
    : above(min);
};

export const only = (breakpoint: Breakpoint): string => {
  const index = breakpointsAsValueOnly.indexOf(breakpoint);
  return index < breakpointsAsValueOnly.length - 1
    ? between(breakpoint, breakpointsAsValueOnly[index + 1])
    : above(breakpoint);
};

export const breakpoints: BreakpointKey = {
  base: above(Breakpoint.base),
  sm: above(Breakpoint.sm),
  md: above(Breakpoint.md - 1),
  lg: above(Breakpoint.lg - 1),
  max: above(Breakpoint.max),
};
