import React from 'react';
import styled from 'styled-components';

const SVGContainer = styled.div``;
const StyledSVG = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
`;

const Defs = () => (
  <defs>
    <symbol id="bitodd-b">
      <path d="M172.33,200H17.9c-2.35,0-4.68-.46-6.85-1.36s-4.15-2.22-5.81-3.88-2.98-3.64-3.88-5.81C.46,186.78,0,184.45,0,182.1V17.91C0,13.16,1.88,8.6,5.24,5.25,8.59,1.89,13.15,0,17.9,0H172.34c3.17,0,6.29,.84,9.02,2.44,2.74,1.6,5,3.9,6.56,6.66l18.15,32.08c1.52,2.69,2.32,5.73,2.32,8.82s-.8,6.13-2.32,8.82l-18.3,32.36c-1.52,2.69-2.32,5.73-2.32,8.82s.8,6.13,2.32,8.82l18.3,32.36c1.52,2.69,2.32,5.72,2.32,8.81s-.8,6.12-2.32,8.81l-18.15,32.09c-1.56,2.76-3.83,5.06-6.56,6.66-2.74,1.6-5.85,2.44-9.02,2.44h0Z" />
    </symbol>
    <symbol id="bitodd-i">
      <path d="M315.13,0h-78.66c-9.9,0-17.92,8.02-17.92,17.91V182.09c0,9.89,8.02,17.91,17.92,17.91h78.66c9.9,0,17.92-8.02,17.92-17.91V17.91c0-9.89-8.02-17.91-17.92-17.91Z" />
    </symbol>
    <symbol id="bitodd-t">
      <path d="M347.2,92.28V20.14c0-11.12,8.42-20.14,18.8-20.14h162.4c10.38,0,18.8,9.02,18.8,20.14V92.28c0,11.12-8.42,20.14-18.8,20.14h-23.93v69.67c0,9.89-8.02,17.91-17.92,17.91h-78.66c-9.9,0-17.92-8.02-17.92-17.91V112.42h-23.96c-10.38,0-18.8-9.01-18.8-20.14h-.01Z" />
    </symbol>
    <symbol id="bitodd-o">
      <path d="M867.83,108.96l-47.52,82.1c-1.57,2.72-3.84,4.98-6.56,6.55s-5.81,2.39-8.96,2.39h-124.16c-3.14,0-6.23-.82-8.95-2.39-2.72-1.57-4.98-3.82-6.56-6.53l-47.53-82.1c-1.58-2.73-2.4-5.82-2.4-8.97s.83-6.24,2.4-8.97l47.53-82.1c1.58-2.72,3.83-4.97,6.56-6.54C674.4,.83,677.48,0,680.63,0h124.13c3.15,0,6.24,.83,8.96,2.4,2.72,1.57,4.99,3.83,6.56,6.55l47.52,82.1c1.58,2.72,2.41,5.81,2.42,8.95,0,3.14-.82,6.23-2.39,8.96Z" />
    </symbol>
    <symbol id="bitodd-d1">
      <path d="M1017.85,200h-120.1c-4.75,0-9.3-1.89-12.66-5.25s-5.24-7.91-5.24-12.66V17.91c0-4.75,1.89-9.3,5.24-12.66,3.36-3.36,7.91-5.25,12.66-5.25h120.1c3.14,0,6.22,.83,8.94,2.4,2.72,1.57,4.99,3.82,6.56,6.54l47.52,82.13c1.57,2.73,2.4,5.82,2.4,8.97s-.83,6.24-2.4,8.97l-47.52,82.13c-1.58,2.7-3.85,4.94-6.57,6.49-2.72,1.56-5.8,2.37-8.93,2.37h0Z" />
    </symbol>
    <symbol id="bitodd-d2">
      <path d="M1231.31,200h-120.1c-4.75,0-9.3-1.89-12.66-5.25s-5.25-7.91-5.25-12.66V17.91c0-4.75,1.89-9.3,5.25-12.66,3.36-3.36,7.91-5.25,12.66-5.25h120.1c3.14,0,6.22,.83,8.94,2.4,2.72,1.57,4.99,3.82,6.56,6.54l47.52,82.13c1.57,2.73,2.4,5.82,2.4,8.97s-.83,6.24-2.4,8.97l-47.52,82.13c-1.58,2.7-3.85,4.94-6.57,6.49-2.72,1.56-5.8,2.37-8.93,2.37Z" />
    </symbol>
    <symbol id="bitodd">
      <path
        vectorEffect="non-scaling-stroke"
        d="M315.14,0h-78.66c-9.9,0-17.92,8.02-17.92,17.91V182.09c0,9.89,8.02,17.91,17.92,17.91h78.66c9.9,0,17.92-8.02,17.92-17.91V17.91c0-9.89-8.02-17.91-17.92-17.91Zm32.07,92.28V20.14c0-11.12,8.42-20.14,18.8-20.14h162.4c10.38,0,18.8,9.02,18.8,20.14V92.28c0,11.12-8.42,20.14-18.8,20.14h-23.93v69.67c0,9.89-8.02,17.91-17.92,17.91h-78.66c-9.9,0-17.92-8.02-17.92-17.91V112.42h-23.96c-10.38,0-18.8-9.01-18.8-20.14Zm-174.87,107.72H17.91c-2.35,0-4.68-.46-6.85-1.36-2.17-.9-4.15-2.22-5.81-3.88-1.66-1.66-2.98-3.64-3.88-5.81-.9-2.17-1.36-4.5-1.36-6.85V17.91C0,13.16,1.89,8.6,5.25,5.25,8.6,1.89,13.16,0,17.91,0H172.35c3.17,0,6.29,.84,9.02,2.44,2.74,1.6,5,3.9,6.56,6.66l18.15,32.08c1.52,2.69,2.32,5.73,2.32,8.82s-.8,6.13-2.32,8.82l-18.3,32.36c-1.52,2.69-2.32,5.73-2.32,8.82s.8,6.13,2.32,8.82l18.3,32.36c1.52,2.69,2.32,5.72,2.32,8.81s-.8,6.12-2.32,8.81l-18.15,32.09c-1.56,2.76-3.83,5.06-6.56,6.66-2.74,1.6-5.85,2.44-9.02,2.44Zm1058.98,0h-120.1c-4.75,0-9.3-1.89-12.66-5.25-3.36-3.36-5.25-7.91-5.25-12.66V17.91c0-4.75,1.89-9.3,5.25-12.66,3.36-3.36,7.91-5.25,12.66-5.25h120.1c3.14,0,6.22,.83,8.94,2.4,2.72,1.57,4.99,3.82,6.56,6.54l47.52,82.13c1.57,2.73,2.4,5.82,2.4,8.97s-.83,6.24-2.4,8.97l-47.52,82.13c-1.58,2.7-3.85,4.94-6.57,6.49-2.72,1.56-5.8,2.37-8.93,2.37Zm-213.46,0h-120.1c-4.75,0-9.3-1.89-12.66-5.25-3.36-3.36-5.24-7.91-5.24-12.66V17.91c0-4.75,1.89-9.3,5.24-12.66,3.36-3.36,7.91-5.25,12.66-5.25h120.1c3.14,0,6.22,.83,8.94,2.4,2.72,1.57,4.99,3.82,6.56,6.54l47.52,82.13c1.57,2.73,2.4,5.82,2.4,8.97s-.83,6.24-2.4,8.97l-47.52,82.13c-1.58,2.7-3.85,4.94-6.57,6.49-2.72,1.56-5.8,2.37-8.93,2.37Zm-150.02-91.04l-47.52,82.1c-1.57,2.72-3.84,4.98-6.56,6.55s-5.81,2.39-8.96,2.39h-124.16c-3.14,0-6.23-.82-8.95-2.39-2.72-1.57-4.98-3.82-6.56-6.53l-47.53-82.1c-1.58-2.73-2.4-5.82-2.4-8.97s.83-6.24,2.4-8.97l47.53-82.1c1.58-2.72,3.83-4.97,6.56-6.54,2.72-1.57,5.8-2.4,8.95-2.4h124.13c3.15,0,6.24,.83,8.96,2.4,2.72,1.57,4.99,3.83,6.56,6.55l47.52,82.1c1.58,2.72,2.41,5.81,2.42,8.95,0,3.14-.82,6.23-2.39,8.96Z"
      />
    </symbol>
    <symbol id="bo">
      <path
        vectorEffect="non-scaling-stroke"
        d="M36.19,41.5H3.76c-.43,0-.85-.08-1.25-.25-.4-.16-.76-.4-1.06-.71-.3-.3-.54-.66-.71-1.06-.16-.4-.25-.82-.25-1.25V3.76c0-.86,.34-1.69,.96-2.31,.61-.61,1.44-.96,2.31-.96H36.19c.58,0,1.14,.15,1.64,.44,.5,.29,.91,.71,1.19,1.21h0l3.81,6.74h0c.28,.49,.42,1.04,.42,1.61s-.15,1.12-.42,1.61h0l-3.84,6.8h0c-.36,.64-.55,1.36-.55,2.1s.19,1.46,.55,2.1h0l3.84,6.8h0c.28,.49,.42,1.04,.42,1.6s-.15,1.11-.42,1.6h0l-3.81,6.74h0c-.28,.5-.7,.92-1.19,1.21s-1.07,.44-1.64,.44Zm48.24-1.63h0c-.29,.5-.7,.91-1.19,1.19-.5,.29-1.06,.44-1.63,.44h-26.07c-.57,0-1.13-.15-1.63-.43-.5-.29-.91-.7-1.19-1.19h0l-9.98-17.24h0c-.29-.5-.44-1.06-.44-1.63s.15-1.14,.44-1.63h0L52.71,2.13c.29-.49,.7-.9,1.19-1.19,.5-.29,1.06-.44,1.63-.44h26.07c.57,0,1.14,.15,1.63,.44,.5,.29,.91,.7,1.19,1.19h0l9.98,17.24h0c.29,.5,.44,1.06,.44,1.63,0,.57-.15,1.13-.43,1.63h0l-9.98,17.24Z"
      />
    </symbol>
    <symbol id="bo-outline">
      <path
        vectorEffect="non-scaling-stroke"
        d="M36.19,41c.49,0,.97-.13,1.39-.38,.42-.25,.77-.6,1.01-1.03l3.81-6.74c.23-.41,.36-.88,.36-1.36s-.12-.94-.36-1.36l-3.84-6.8h0c-.4-.72-.62-1.52-.62-2.34s.21-1.63,.62-2.34h0l3.84-6.8c.23-.41,.36-.88,.36-1.36s-.12-.95-.36-1.36l-3.81-6.74c-.24-.43-.59-.78-1.01-1.03-.42-.25-.9-.38-1.39-.38H3.76c-.73,0-1.43,.29-1.95,.81-.52,.52-.81,1.22-.81,1.95V38.24c0,.36,.07,.72,.21,1.06,.14,.33,.34,.64,.6,.9,.26,.26,.56,.46,.9,.6,.34,.14,.69,.21,1.06,.21H36.19Zm3.24-21.85c-.32,.57-.49,1.2-.49,1.85s.17,1.29,.49,1.85l3.84,6.8c.32,.56,.49,1.2,.49,1.85s-.17,1.29-.49,1.85l-3.81,6.74c-.33,.58-.8,1.06-1.38,1.4-.58,.34-1.23,.51-1.9,.51H3.76c-.49,0-.98-.1-1.44-.29-.46-.19-.87-.47-1.22-.82-.35-.35-.63-.76-.82-1.22-.19-.46-.29-.95-.29-1.44V3.76c0-1,.4-1.95,1.1-2.66C1.81,.4,2.76,0,3.76,0H36.19c.67,0,1.32,.18,1.9,.51,.58,.34,1.05,.82,1.38,1.4l3.81,6.74c.32,.57,.49,1.2,.49,1.85s-.17,1.29-.49,1.85l-3.84,6.8Zm44.57,20.47l9.98-17.24h0c.24-.42,.37-.9,.37-1.38,0-.48-.13-.96-.37-1.38L83.99,2.38c-.24-.42-.59-.77-1.01-1.01-.42-.24-.9-.37-1.38-.37h-26.07c-.48,0-.96,.13-1.38,.37-.42,.24-.77,.59-1.01,1.01l-9.98,17.24h0c-.24,.42-.37,.9-.37,1.38s.13,.96,.37,1.38h0l9.98,17.24c.24,.42,.59,.77,1.01,1.01,.42,.24,.9,.37,1.38,.37h26.07c.48,0,.96-.13,1.38-.37,.42-.24,.77-.59,1.01-1.01Zm-31.72,.5l-9.98-17.24c-.33-.57-.51-1.22-.51-1.88s.17-1.31,.51-1.88L52.28,1.88c.33-.57,.81-1.04,1.38-1.37,.57-.33,1.22-.5,1.88-.5h26.07c.66,0,1.31,.17,1.88,.5,.57,.33,1.05,.8,1.38,1.38l9.98,17.24c.33,.57,.51,1.22,.51,1.88,0,.66-.17,1.31-.5,1.88l-9.98,17.24c-.33,.57-.81,1.05-1.38,1.37-.57,.33-1.22,.5-1.88,.5h-26.07c-.66,0-1.31-.17-1.88-.5-.57-.33-1.05-.8-1.38-1.37Z"
      />
    </symbol>
    <symbol id="v1">
      <path d="M9.22,25.28L0,.46H1.38l7.13,19.43,1.42,3.76,1.42-3.79L18.51,.46h1.31L10.57,25.28h-1.35Zm22.27-1.17h3.76v1.17h-9.15v-1.17h4.15V2.84c-1.28,3.07-3.53,4.95-6.77,5.64v-1.24c3.45-.8,5.71-3.06,6.77-6.77h1.24V24.11Zm10.76,1.17h-2.13v-3.08h2.13v3.08Zm12.12,.46c-1.7,0-3.18-.52-4.43-1.56-1.23-1.04-2.19-2.52-2.87-4.43-.66-1.94-.99-4.22-.99-6.84s.33-4.9,.99-6.84c.69-1.94,1.64-3.43,2.87-4.47,1.25-1.06,2.73-1.6,4.43-1.6s3.17,.53,4.4,1.6c1.25,1.04,2.21,2.53,2.87,4.47,.66,1.94,.99,4.22,.99,6.84s-.33,4.9-.99,6.84c-.66,1.91-1.62,3.39-2.87,4.43-1.23,1.04-2.69,1.56-4.4,1.56Zm0-1.17c2.27,0,3.99-1.03,5.18-3.08,1.21-2.06,1.81-4.92,1.81-8.58s-.6-6.54-1.81-8.62c-1.18-2.08-2.91-3.12-5.18-3.12s-4.01,1.04-5.21,3.12c-1.21,2.08-1.81,4.95-1.81,8.62s.6,6.52,1.81,8.58c1.21,2.06,2.94,3.08,5.21,3.08Z" />
    </symbol>
    <symbol id="arrow-tip" viewBox="0 0 32 89.82">
      <path
        vectorEffect="non-scaling-stroke"
        d="M0,89.82c4.06-.47,7.7-2.82,9.77-6.41L28.26,51.41c1.16-2.01,1.74-4.26,1.74-6.5h0c0-2.25-.58-4.49-1.74-6.5L9.77,6.41C7.7,2.82,4.06,.47,0,0"
      />
    </symbol>
    <symbol id="close-x" viewBox="0 0 30 30">
      <path d="M3,3l24,24 M27,3L3,27" vectorEffect="non-scaling-stroke" />
    </symbol>
  </defs>
);

const SVGDefs = () => (
  <SVGContainer>
    <StyledSVG version="1.1" xmlns="http://www.w3.org/2000/svg">
      <Defs />
    </StyledSVG>
  </SVGContainer>
);

export default SVGDefs;
