import { createGlobalStyle } from 'styled-components';
import reset from './global/cssReset';
import responsiveFontSize from './global/responsiveFontSize';
/*
import debugLayoutGrid from './global/debugLayoutGrid';
import innerHtmlElements from './global/innerHtmlElements';
import layoutGrid from './global/layoutGrid';
import reset from './global/reset';
import responsiveFontSize from './global/responsiveFontSize';
import theming from './global/theming';

const DEBUG = false;
*/
const GlobalStyle = createGlobalStyle`
  ${reset}
  ${responsiveFontSize}
  :root {
    --bo-black: #202020;
    --bo-pink: #f9b4ee;
    --bo-yellow: #feea7e;
    --bo-blue: #89d4ff;
    --bo-white: #ffffff;
  }

    
  div {
    &.withTrails {
      box-shadow: 0 var(--scroll-speed) var(--bo-pink), 0 calc(var(--scroll-speed) * 2) var(--bo-blue), 0 calc(var(--scroll-speed) * 3) var(--bo-yellow);    
    }
  }

  h1, h2, h3, p, span {
    &.withTrails {
      text-shadow: 0 var(--scroll-speed) var(--bo-pink), 0 calc(var(--scroll-speed) * 2) var(--bo-blue), 0 calc(var(--scroll-speed) * 3) var(--bo-yellow);    
    }
  }
`;

export default GlobalStyle;
