import { css } from 'styled-components';
import { Breakpoint, breakpoints } from './breakpoints';

const diffTablet = Breakpoint.lg - Breakpoint.md;
const diffDesktop = Breakpoint.max - Breakpoint.lg;

const responsiveFontSize = css`
  html {
    font-size: 100%;
  }

  ${breakpoints.md} {
    html {
      font-size: calc(
        16px + 5.33 * ((100vw - ${Breakpoint.md}px) / ${diffTablet})
      );
    }
  }

  ${breakpoints.lg} {
    html {
      font-size: calc(
        10.24px + 5.76 * ((100vw - ${Breakpoint.lg}px) / ${diffDesktop})
      );
    }
  }

  ${breakpoints.max} {
    html {
      font-size: 100%;
    }
  }
`;
export default responsiveFontSize;
