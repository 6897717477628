import React from 'react';
import GlobalStyle from '../../styles/GlobalStyle';
import SVGDefs from '../../svg/svg-defs';
import Helmet from 'react-helmet';
// import Loader from '../../components/loader/Loader';

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Hand-crafted games from Helsinki, Finland. Jeux faits à la main, fabriqué à Helsinki, Finlande. ヘルシンキ、フィンランドの厳選された職人達によって生み出されたゲーム"
        />
        <title>BIT ODD</title>
      </Helmet>

      <GlobalStyle />
      <SVGDefs />
    
      {/* <Loader /> */}
      {children}
    </>
  );
};

export default Layout;
